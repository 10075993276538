const React = require("react")

const { Auth0Provider } = require("@auth0/auth0-react")
const { navigate } = require("gatsby")

exports.onRouteUpdate = ({ location, prevLocation }) => {
  if (typeof window._satellite !== "undefined") {
    // Dispatch route change event to target in a rule.
    window._satellite.track("kpv")
  }
}

exports.onClientEntry = () => {
  const script = document.createElement("script")
  script.src =
    "https://www.gstatic.com/dialogflow-console/fast/messenger/bootstrap.js?v=1"
  script.async = true
  script.defer = true
  document.body.appendChild(script)
}

const onRedirectCallback = (appState) => {
  // Use Gatsby's navigate method to replace the url
  navigate(appState?.returnTo || "/", { replace: true })
}

// Wraps every page in a component
exports.wrapRootElement = ({ element }) => {
  return (
    <Auth0Provider
      domain={process.env.GATSBY_AUTH0_DOMAIN}
      clientId={process.env.GATSBY_AUTH0_CLIENTID}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      organization={process.env.GATSBY_AUTH0_ORGID}
    >
      {element}
    </Auth0Provider>
  )
}
